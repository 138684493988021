import { makeAutoObservable } from "mobx";
import { LoaderShelf } from "@startapp/mobx-utils";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import {
	api,
	Errors,
	showErrorToast,
	showSuccessToast,
	strings,
} from "~/resources";

export default class ContactStore {
	public loader = new LoaderShelf();
	public form = new FormShelf({
		name: "",
		email: "",
		phone: "",
		message: "",
	});

	constructor(){
		makeAutoObservable(this);
	}

	public createContact = async(onSuccess: () => void) => {
		try{
			this.loader.tryStart();
			const formValues = this.form.getValues();
			await api.createContact(formValues);
			showSuccessToast(strings.screen.contact.success);
			onSuccess();
			this.onClear();
		}catch(e){
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		}finally{
			this.loader.end();
		}
	};

	public onClear = () => {
		const formValues = this.form.getValues();

		formValues.name = "";
		formValues.email = "";
		formValues.message = "";
		formValues.phone = "";
	};
}
