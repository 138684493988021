import React from "react";
import type { NextPage } from "next";
import { observer, useLocalObservable } from "mobx-react-lite";
import { OgTags } from "~/components/Ogtags";
import { useRouter } from "next/router";
import {
	SectionClients,
	SectionAvailableClinics,
	ContactForm,
	IntroductionSection,
} from "~/components";
import { ContactField } from "~/components/ContactForm";
import ContactStore from "~/stores/ContactStore";
import ClinicsStore from "~/stores/ClinicsStore";
import { homeOgTags } from "~/resources/PageOgTags";
import { strings } from "~/resources";
import { MainLayout } from "~/layout";

const Home: NextPage = () => {

	const contactStore = useLocalObservable(() => new ContactStore());
	const clinicsStore = useLocalObservable(() => new ClinicsStore());

	const onChangeInput = (field: ContactField, value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		contactStore.form.field(field).onChange(value);
	};

	const router = useRouter();

	const goToHome = () => {
		router.push("/");
	};

	const initialSectionStrings = strings.components.initialSection;
	const clientsSectionsStrings = strings.components.clientsSections;
	return (
		<MainLayout noDivider>
			<IntroductionSection
				subTitle={initialSectionStrings.subTitle}
				title={initialSectionStrings.title}
				srcImage={initialSectionStrings.srcImage}
				description={initialSectionStrings.description}
			/>
			<OgTags {...homeOgTags} />
			<SectionAvailableClinics clinics={clinicsStore.paginatedListShelf.items}/>
			<SectionClients
				clients={clientsSectionsStrings}
			/>
			<ContactForm
				contact={contactStore.form.getValues()}
				onHandleChange={onChangeInput}
				onSubmit={() => contactStore.createContact(goToHome)}
				loading={contactStore.loader.isLoading}
			/>
		</MainLayout>

	);
};

export default observer(Home);
