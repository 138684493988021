import { IProps as IOgTagsProps } from "~/components/Ogtags";
import Routes from "~/resources/routes";

const baseUrl = `https://${process.env.NEXT_PUBLIC_PAGE_URL || "vetepet24h.com.br"}`;

export const homeOgTags: IOgTagsProps = {
	type: "website",
	title: "Vet & Pet",
	description: "Vet & Pet.",
	themeColor: "#000000",
	keywords: [
		"Desenvolvimento na StartApp",
		"Vet",
		"Pet",
		"PWA",
	],
	twitter: {
		type: "summary",
	},
	image: {
		url: `${baseUrl}/logo.png`,
		type: "image/png",
		width: 1024,
		height: 1024,
	},
	url: `${baseUrl}${Routes.index().href}`,
};
