import { makeAutoObservable } from "mobx";
import { PaginatedListShelf } from "@startapp/mobx-utils";
import { api } from "~/resources";
import { onFetchError } from "~/resources/fetchError";

class ClinicsStore {

	public paginatedListShelf: PaginatedListShelf<api.AdminUserToSite>;

	constructor() {
		makeAutoObservable(this);
		this.paginatedListShelf = new PaginatedListShelf(
			api.getAllShowInSiteClinicAdminUsers,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

}

export default ClinicsStore;
